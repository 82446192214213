import { useNavigate } from 'react-router-dom';

/**
 * Hook permettant de gérer l'authentification
 * Il permet d'accéder à plusieurs éléments
 * - login: le login de l'utilisateur connecté
 * - token: le token de l'utilisateur connecté
 * - loginAction: fonction permettant de se connecter (stoker les informations de connexion dans le local storage et rediriger vers la page /route1)
 * - logOutAction: fonction permettant de se déconnecter (supprimer les informations de connexion du local storage et rediriger vers la page /login)
 * 
 * @returns 
 */
export default function useAuth() {
  let navigate = useNavigate();

  const token = localStorage.getItem("token") || "";
  let login = localStorage.getItem("login") || "";

  function loginAction(newLogin: string, token: string)  {
    login = newLogin;
    localStorage.setItem("token", token);
    localStorage.setItem("login", login);
    navigate("/route1");
  }

  function logOutAction() {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    navigate("/login");
  }
  return { login, token, loginAction, logOutAction };
};