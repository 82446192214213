import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from '../hooks/useAuth'
import { Layout } from "./Layout";


/**
 * Composant ayant plusieurs utilités:
 * - Vérifier si l'utilisateur est connecté (ou redirection vers la page de login)
 * Afficher le composant Layout
 * @returns 
 */
const PrivateRoute = () => {
  const auth = useAuth();
  if (!auth.token) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <Layout />
    </>
  );
};

export default PrivateRoute;
