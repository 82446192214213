import React from 'react';


export default function Page2() {
    
      return (
        <div >
          <p>Page2</p>
        </div>
      );
}


