import axios from 'axios';

/**
 * Axios est le composant utilisé pour effectuer des requêtes HTTP
 * une instance est créée pour ajouter un intercepteur qui ajoute automatiquement 
 * le token de connexion à chaque requête ennvoyée, dans le cas où on est connectés
 */
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token") 
    if (token) {
      // Bearer est une convention pour les tokens JWT
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;