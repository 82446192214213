import { ClipLoader } from 'react-spinners';
import React from 'react';

type SpinnerProps = {
  legend?:string
}
export default function Spinner({legend=""}:SpinnerProps) {
  return (
      <div className='flex flex-rows gap-4 items-center justify-center w-full h-full'>
        <ClipLoader size={24} color="gray"/>
        <div>{legend}</div>
      </div>
  )
}