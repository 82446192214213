import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Libelle } from '../model/Libelle';
import { useQuery } from 'react-query';
import QueryCacheName from '../utils/QueryCacheName';
import Spinner from '../components/Spinner';
import ErrorUtils from '../utils/ErrorUtils';
import { toast } from 'react-toastify';

export default function Page1() {
  // Function to fetch libelles
  const fetchLibelles = async () => {
    const response = await axiosInstance.get('/api/libelle/list');
    return response.data;
  };

  // Using react-query to manage fetching state
  const { data: libellesList, isLoading, isError, error } = useQuery<Libelle[], AxiosError>(
    QueryCacheName.getLibelleList(),
    fetchLibelles
  );

  // Use useEffect to show the toast on error
  useEffect(() => {
    if (isError && error) {
      const errorMessage = ErrorUtils.getErrorMessage(error);
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000, // Auto close after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [isError, error]);

  // Render loading state
  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full">
        <Spinner />
      </div>
    );
  }

  // Render libelles list or an empty message if no data
  return (
    <div className="w-full">
      <h2 className="text-lg font-bold">Liste des libellés :</h2>
      {libellesList && libellesList.length > 0 ? (
        libellesList.map((libelle: Libelle) => (
          <div key={libelle.contexte} className="mb-4">
            <p className="font-semibold">{libelle.libelleFr}</p>
            <p>{libelle.libelleTraduit}</p>
            <p className="text-sm text-gray-500">{libelle.contexte}</p>
          </div>
        ))
      ) : (
        <p className="text-gray-500">
          {libellesList ? 'Aucun libellé disponible.' : 'Chargement des libellés...'}
        </p>
      )}
    </div>
  );
  
}
