import React, { useState } from 'react';
import { User } from 'lucide-react'; // Import the User icon
import useAuth from '../hooks/useAuth';

const MenuHorizontal = () => {
  const auth = useAuth();
  const userName = auth.login;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header className="flex items-center justify-between bg-white text-black py-4 px-4 shadow-md h-16 border-b-4 border-blue-900">
      <img className="h-12" src="/assets/forterro.png" alt="Company Logo" />
      <div className="relative text-black">
          <button className="flex items-center" onClick={toggleDropdown}>
            <User className="icons-color" size={24} />
            <span className="text-lg font-semibold ml-2">{userName}</span>
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 bg-white text-black shadow-md rounded-lg">
              <button className="block w-full text-left px-4 py-2 hover:bg-gray-200 text-center">
                View Profile
              </button>
              <button className="block w-full text-left px-4 py-2 hover:bg-gray-200 text-center">
                Settings
              </button>
              <button className="block w-full text-left px-4 py-2 hover:bg-gray-200 text-center" onClick={() => auth.logOutAction()}>
                Logout
              </button>
            </div>
          )}
        </div>
      
    </header>
  );
};

export default MenuHorizontal;
