import React from "react";
import { NavLink } from "react-router-dom";
import { BookType, ArrowDownUp } from 'lucide-react';

// Define the props type for the Menu component
interface MenuProps {
  isCollapsed: boolean; // Define the type for isCollapsed as boolean
}

/**
 * Représente le menu de l'application
 * NavLink permet de créer des liens vers les différentes pages, tout en
 * gardant l'information de quelle page est active
 * @param {MenuProps} props - Props for the Menu component
 * @returns 
 */
function Menu({ isCollapsed }: MenuProps) {
  return (
    <>
    
    <nav className="w-full flex flex-col justify-between">
      <NavLink to="/route2" className="menu-element flex items-center p-2">
        {({ isActive }) => (
          <div className={`menu-item flex items-center rounded-3-xl p-2 transition duration-200 hover:rounded-xl ${isActive ? "bg-indigo-900 text-white" : "text-black"}`}>
            <ArrowDownUp className={`${isActive ? "text-white" : ""}`} size={24} />
            {!isCollapsed && <div className="ml-3">Phases</div>}
          </div>
        )}
      </NavLink>

      <NavLink to="/route1" className="menu-element flex items-center p-2">
        {({ isActive }) => (
          <div className={`menu-item flex items-center rounded-lg p-2 transition duration-200 ${isActive ? "bg-indigo-900 text-white" : "text-black"}`}>
            <BookType className={`${isActive ? "text-white" : ""}`} size={24} />
            {!isCollapsed && <div className="ml-3">Traductions</div>}
          </div>
        )}
      </NavLink>
    </nav>

    </>
  );
}

export default Menu;
