import { AxiosError } from "axios";

export default class ErrorUtils {
    public static getErrorMessage(error:unknown): string {
        if (error instanceof AxiosError) {
            return this.getAxiosErrorMessage(error);
        } else if (error instanceof Error) {
            return error.message;
        } else if (error instanceof String) {
            return error.toString();
        }         
        return "Erreur technique";
    }

    private static getAxiosErrorMessage(error:AxiosError): string {
        let data:any = error.response?.data;
        if (data.message) {
            return data.message;
        } else if(data.violations) {
            // Cas des erreurs de validation "automatique" de bean (ex. annotations NotBlank sur les attributs côté serveur)
            return data.violations.map((violation:any) => violation.message).join(", ");
        } else if(data) {
            return data.toString();
        }
        return "Erreur innatendue";
    }
}