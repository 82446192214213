import React, { useEffect, useState } from 'react';
import { MonitorUp } from 'lucide-react';
import { useMutation } from 'react-query';

import useAuth from '../hooks/useAuth'
import axiosInstance from '../utils/axiosInstance';
import Spinner from '../components/Spinner';
import { AxiosError } from 'axios';
import ErrorUtils from '../utils/ErrorUtils';

/**
 * Contenu de la page de login
 * TODO : passer par les composants de formulaire de react plutôt que de tout gérer à la main
 * @returns 
 */
export default function PageLogin() {
  const [fieldLoginValue, setFieldLoginValue] = useState('');
  const [fieldPasswordValue, setFieldPasswordValue] = useState('');
  const [validationState, setValidationState] = useState(false)
  const auth = useAuth();

  const loginMutation = useMutation(
    (params: any) => axiosInstance.post('/api/auth', params), {
    onSuccess: (resp: any) => {
      auth.loginAction(fieldLoginValue, resp.data.token);
      console.log("Login successful, redirection vers /menu1! token récupéré : " + resp.data.token);
    },
    onError: (error: AxiosError) => {
      console.error('Erreur query :', JSON.stringify(error));
    }
  });

  // Change l'état de validation du formulaire quand un champ évolue
  useEffect(() => {
    validateFields();
  }, [fieldLoginValue, fieldPasswordValue]);

  const login = () => {
    if (validationState) {
      loginMutation.mutate({ login: fieldLoginValue, password: fieldPasswordValue });
    }

  };

  function validateFields() {
    if (fieldLoginValue.length > 0 && fieldPasswordValue.length > 0) {
      setValidationState(true);
    } else {
      setValidationState(false);
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      login();
    }
  }

  return (
    <div className="border-style bg-zone texte mx-auto p-10 m-10 h-fit w-1/3 grid space-y-4">
      <label className="col-span-3 font-bold">Veuillez vous connecter pour accéder à l'application</label>
      {loginMutation.isError &&
        <div className="error-texte col-span-3">
            Impossible de se connecter :   {ErrorUtils.getErrorMessage(loginMutation.error)}
        </div>
      }
      <label>Identifiant:</label>
      <input
        className="col-span-2 px-2 py-1"
        name="username"
        autoComplete="username"
        onChange={(event) => { setFieldLoginValue(event.target.value); }}
        onKeyDown={handleKeyDown}
      />
      <label >Mot de passe:</label>
      <input
        className="col-span-2 px-2 py-1"
        type="password"
        name="password"
        autoComplete="password"
        onChange={(event) => { setFieldPasswordValue(event.target.value); }}
        onKeyDown={handleKeyDown}
      />
      <button className='button-style col-span-3 flex justify-center' onClick={login} disabled={loginMutation.isLoading || !validationState}>
        {loginMutation.isLoading ?
          <>
            <Spinner legend='Connexion...' />
          </>
          :
          <>
            <MonitorUp className="icons-color " size={28} />
            <div className="align-text-bottom">Se connecter</div>
          </>
        }
      </button>
    </div>
  );
}

