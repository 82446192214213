import React, { useState } from "react";
import MenuHorizontal from "./MenuHorizontal";
import Menu from "./Menu";
import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import { ChevronsLeft, ChevronsRight } from "lucide-react";

export function Layout() {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      {/* Horizontal Menu */}
      <div className="w-full h-16">
        <MenuHorizontal />
      </div>

      {/* Main Layout */}
      <div className="flex h-[calc(100vh-4rem)]">
        {/* Navbar */}
        <div className={`flex flex-col transition-all duration-300 justify-between
          ${isCollapsed ? 'w-[10%] min-w-[120px] max-w-[200px]' : 'w-[15%] min-w-[180px] max-w-[300px]'} 
          bg-gray-200 pt-4`} >
            <div className="">
              <Menu isCollapsed={isCollapsed} />
            </div>
            <div className="">
                <button
                className="flex flex-row items-center text-black py-2 px-4 hover:bg-gray-300 
                transition duration-300 w-full"
                onClick={toggleCollapse}
              >
                {isCollapsed ? (
                  <ChevronsRight />
                ) : (
                  <>
                    <ChevronsLeft /> Collapse
                  </>
                )}
              </button>
            </div>
        </div>

        {/* Content Area */}
        <div
          className={`transition-all duration-300 p-4 bg-gray-100 min-h-[calc(100vh-4rem)] 
          overflow-y-auto overscroll-contain ${isCollapsed ? 'w-[95%]' : 'w-[90%]'}`}
        >
          <Outlet />
          <ToastContainer />
        </div>
      </div>
    </>
  );
}
