import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes , Navigate} from "react-router-dom";
import PrivateRoute from './components/PrivateRoute';
import { toast } from "react-toastify";
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import { QueryClient, QueryClientProvider } from 'react-query';
import PageLogin from './pages/PageLogin';
import { AxiosError } from 'axios';

/**
 * Configuration de l'objet qui sera utilisé pour les requêtes et les mutations
 * de l'application
 */
const queryClient = new QueryClient({
  
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false, 
      retry: false,
      onError: (error: any) => {
        if (error instanceof AxiosError) {
          if(error.response?.status === 401) {
            toast.error("Vous devez être connecté pour accéder à cette ressource, veuillez vous reconnecter")
            //TODO, trouver un moyen de se déconnecter proprement et de rediriger vers la page de login ?
          } else {
            console.log("Erreur query : " +  JSON.stringify(error))
            toast.error(error.message + " (" + error.status + ")")
          }
        } else {
          console.log("Erreur query : " + JSON.stringify(error))
          toast.error(error.message)
        }
      }
    },
    mutations: {
      onError: (error: any) => {
        console.log("Erreur mutation : " + error)
        //FIXME, voir pourquoi le toast ne s'affiche pas
        // Quand il y a une erreur lors d'une mutation
        toast.error(error)
        console.log("APRES TOAST : " + error)
      }
    }, 
  },
})

/**
 * Englobe toute l'application
 * 
 * React.FC permet de typer le composant comme étant un functionnal component react
 *  * Améliore l'auto-complétion dans les éditeurs de code
 *  * Simplifie la gestion des props, y compris children
 * @returns 
 */
const App: React.FC = () => {
  return (
    <div className="overflow-hidden bg-stone-50 min-w-[400px] min-h-screen">
      <Router>
            <QueryClientProvider client={queryClient}>
              <Routes> 
                <Route path="/login" element={<PageLogin />} />
                <Route path="/" element={<Navigate to="/route1" />}/>
                <Route element={<PrivateRoute />}>
                  <Route path="/route1" element={<Page1 />} />
                  <Route path="/route2" element={<Page2 />} />
                </Route>
              </Routes>
            </QueryClientProvider>
        </Router>
      </div>
  );
}

export default App;
